<script lang="ts" setup>

    const config = useRuntimeConfig()

    const { $preview } = useNuxtApp()
    const { slug } = useRoute().params
    const url = slug && slug.length > 0 ? slug.join('/') : 'home'
    
    const version = $preview ? 'draft' : 'draft'
    const cv = config.public.cv 

    const resolveLinks = 'url'
    const resolveRelations = 'NewsArticleTemplate.categories,CaseStudiesArticleTemplate.categories'

    const { data: story, pending } = await useAsyncData(
        url,
        async () => {
            const { data } = await useStoryblokApi().get(`cdn/stories/${url.replace(/\/$/, '')}`, {
                cv: cv,
                version,
                resolve_links: resolveLinks,
                resolve_relations: resolveRelations
            })
            return data?.story
        }
    )

    onMounted(() => {

        if ($preview && story.value && story.value.id) {
            useStoryblokBridge(story.value.id, (evStory:any) => story.value = evStory, { resolveRelations } )
        }

    })

    definePageMeta({
        layout: 'default'
    })
    
</script>

<template>
    <StoryblokComponent v-if="pending === false && story" :story="story" :page="story" :blok="story.content" class="overflow-hidden" />
</template>